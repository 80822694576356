
/**
 * DOI-HINT
 */
:lang(de).getsFreeCoinsForDoi{
  background-image: url(../img/freecoins/doi_hint_overlay_de.jpg) !important;
}
:lang(en).getsFreeCoinsForDoi{
  background-image: url(../img/freecoins/doi_hint_overlay_en.jpg) !important;
}


/**
 * PAYMENT
 */
@media (min-width: 1024px) {
  :lang(de).overlay > .payment-box > .overlay-content.showFreeCoinsBanner > .box {
    background-image: url(../img/freecoins/payment_overlay_header_de.jpg) !important;
  }

  :lang(en).overlay > .payment-box > .overlay-content.showFreeCoinsBanner > .box {
    background-image: url(../img/freecoins/payment_overlay_header_en.jpg) !important;
  }
}
