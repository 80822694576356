[onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg {
  background-image: url(../img/signup_background.jpg);
  background-size: cover;
  background-position: bottom left;
  background-clip: content-box;
}

@media (min-width: 1280px) {
  [onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg {
    padding-top: 28%;
  }

  [onsw-hook][hookname="welcomeGuest"].ons-layout.col50-lg > .box {
    background-image: url(../img/signup_background.jpg);
    background-size: cover;
    background-position: bottom left;
    left: 10px;
    right: 10px;
  }
}

onsw-template.welcomeGuest {
  top: 84px;
}
