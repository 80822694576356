/* spc */
@import '../css/messenger/voicemessage.css';
@import '../css/messenger/voicemessageRecorder.css';

.layout-toolbar.messenger-voice {
  --voicemessage-msg-out-wave-color: black;
  --voicemessage-msg-out-wave-progress-color: #f90;
  --voicemessage-msg-out-btn-color: #666;
  --voicemessage-msg-out-btn-color-hover: black;
  --voicemessage-msg-out-btn-bg-active: #f90;
  --voicemessage-msg-out-btn-color-active: white;
  --voicemessage-msg-out-duration-color: #8a8a8a;
  --voicemessage-msg-in-wave-color: var(--voicemessage-msg-out-wave-color);
  --voicemessage-msg-in-wave-progress-color: var(--voicemessage-msg-in-btn-bg-active);
  --voicemessage-msg-in-btn-color: var(--voicemessage-msg-out-btn-color);
  --voicemessage-msg-in-btn-color-hover: var(--voicemessage-msg-out-btn-color-hover);
  --voicemessage-msg-in-btn-bg-active: var(--voicemessage-msg-out-btn-bg-active);
  --voicemessage-msg-in-btn-color-active: var(--voicemessage-msg-out-btn-color-active);
  --voicemessage-msg-in-duration-color: var(--voicemessage-msg-out-duration-color);
}

.messenger-voice-recorder {
  --record-btn-bg: linear-gradient(to bottom, #d47f01 0%, #f90 100%);
  --record-btn-border: 1px solid #f90;
  --record-btn-hover-bg: #f90;
  --voicemessage-recorder-wave-color: black;
  --voicemessage-recorder-wave-progress-color: #f90;
  --voicemessage-recorder-btn-color: #888;
  --voicemessage-recorder-btn-color-hover: black;
  --voicemessage-recorder-btn-bg-active: #888;
  --voicemessage-recorder-btn-color-active: white;
  --voicemessage-recorder-text-color: #8a8a8a;
}
