:root {
  .layout-header {
    --logo-url: url('../img/logo.svg');
    --logo-xs-url: url('../img/logo-xs.svg');
  }

  --freemovie-logo: url('../img/fmotd/logo.png');

  --hotornot-teaser-logo: url(../img/hotornot/logo.png);
  --hotornot-teaser-logo-en: url(../img/hotornot/logo.png);
  --hotornot-teaser-bg-url: url(../img/hotornot/bgTeaser.jpg);

  --countdown-default-bg: linear-gradient(to bottom, #bfdc7a 0%, #89a509 100%);
  --countdown-default-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
  --countdown-default-border: 3px solid white;
  --countdown-sidebar-top-position: 350px;
  --countdown-sidebar-wellcum-top-position: 280px;
}
