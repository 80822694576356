.ons-layout.overlay > .box.withBonus > .overlay-toolbar.payment > .box > .ons-item > .box > .icon * {
  fill: #666;
}
.ons-layout.overlay-toolbar.bonusOverlay > .box > .ons-item > .box > .icon * {
  fill: #666;
}

.ons-text.emptyList > .box > .text::before {
  display: block;
  content: '';
  background-image: url(../img/noResult.png);
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  height: 150px;
}
