@import '../css/layout/item.css';
@import '../css/layout/overlay.css';

/**
 * ## Preset: SPC Overlay
 * This preset fits serveral purposes.
 * - The overlay is presented in a slightly highlighted color scheme.
 * - The popup too.
 * - Also the popups inside the messenger need to be reverted in color??
 */
.preset-overlay,
.ons-layout.overlay,
.layout-container.layout-overlay {

  .layout-panel {
    --panel-background: #444;
    --panel-color: #eee;
  }

  .layout-item {
    --item-background: #333;
  }

  .layout-toolbar {
    --toolbar-icon-color: #d1d1d1;
  }
}
