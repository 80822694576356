[onsw-payment-packages].payment .package.packageSelected > .box {
  border-color: #89a509;
}
[onsw-payment-packages].payment .package.packageSelected > .box  > div.note {
  color: white;
}
[onsw-payment-progress].step .bar div {
  background: #89a509;
  background: linear-gradient(to bottom,  #BFDC7A 0%,#89a509 100%);
}
[onsw-payment-progress].step li.active div,
[onsw-payment-progress].step li.finalized div {
  background: #89a509;
  background: linear-gradient(to bottom,  #BFDC7A 0%,#89a509 100%);
}
[onsw-payment-progress].step li.active p {
  color:#89a509;
}
[onsw-payment-mobile-payment].payment .package,
[onsw-payment-packages].payment .package {
  font-family: var(--default-font);
  font-weight: 400;
}

[onsw-payment-mobile-payment].payment .package > .box  > .ons-item,
[onsw-payment-packages].payment .package.packageSelected > .box  > .ons-item,
[onsw-payment-packages].payment .package > .box  > .ons-item {
  background: #89a509;
  background: linear-gradient(to bottom,  #BFDC7A 0%,#89a509 100%);
}
[onsw-payment-packages].payment .package.packageSelected > .box {
  border-color: #89a509;
}
[onsw-payment-packages].payment .package .value.coins .bonus {
  font-size: 26px;
}
[onsw-payment-packages].payment .package .value.coins .default {
  font-size: 20px;
}
[onsw-payment-mobile-payment].payment .package > .box  > .ons-item.bonus:after,
[onsw-payment-packages].payment .package > .box  > .ons-item.bonus:after {
  color: #333;
}

